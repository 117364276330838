<template>
  <div class="slider-edit-form">
    <BaseBreadcrumbs
      :breadcrumbs="[
        { link: '/portal/cms/dashboard', title: 'Dashboard' },
        { link: '/portal/cms/dashboard/edit-slider', title: 'Edit Slider' },
      ]"
    />
    <div class="controls">
      <BaseTabSelector v-model="activeSlide" :tabList="slides" showIndex />
      <span v-if="slides.length < 5" @click="addSlide" @keyup.enter="addSlide" class="button" tabindex="0">
        <PlusIcon class="plus" />Add a new slide
      </span>
      <span
        v-if="slides.length > 1"
        @click="isConfirmationModalOpen = true"
        @keyup.enter="isConfirmationModalOpen = true"
        class="button close"
        tabindex="0"
      >
        <CloseIcon class="x" />Remove slide
      </span>
    </div>
    <form class="slider-form" autocomplete="off" @submit.prevent="submitForm">
      <section
        v-for="slide in slides"
        :key="slide.value || slide.id"
        v-show="slide.value === activeSlide.value || slide.id === activeSlide.value"
      >
        <div class="form-group" :class="{ 'has-error': currentErrors.name }">
          <label for="slider-text" class="form-label">Text</label>
          <input
            v-model="slide.text"
            maxlength="50"
            type="text"
            class="form-control"
            id="slider-text"
            placeholder="Slider text"
            :class="{ 'is-invalid': currentErrors.name }"
          />
          <template v-if="currentErrors.name">
            <div v-for="message in currentErrors.name.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <div class="form-group" :class="{ 'has-error': currentErrors.assetType }">
          <BaseFileUpload
            @file-added="fileAdded({ event: $event, slide })"
            @file-error="fileUploadErrorHandler($event)"
            :class="{ 'is-invalid': currentErrors.assetFile }"
            :existingFileName="slide.backgroundImage"
            fileTypesAccept=".jpg, .jpeg, .png"
            isRequired
            label="Background Image"
          />
          <template v-if="currentErrors.assetFile">
            <div v-for="message in currentErrors.assetFile.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
          <sub class="form-text">
            Image uploads cannot exceed 15 MB in size.<br />Accepted file types: JPG, JPEG, & PNG
          </sub>
        </div>

        <label class="form-label">Preview Image</label>
        <div class="preview-container">
          <span class="text">{{ slide.text }}</span>
          <img v-if="objectUrl" :src="objectUrl" />
          <img v-else-if="slide.presignedBackgroundImage" :src="slide.presignedBackgroundImage" />
          <div v-else class="placeholder"></div>
        </div>

        <!-- Cropper Modal -->
        <transition name="fade">
          <aside v-show="isCropperOpen" class="modal">
            <div class="container">
              <h2>Crop Image</h2>
              <VueCropper
                v-if="objectUrl"
                :src="objectUrl"
                :viewMode="2"
                :aspectRatio="3.5 / 1"
                :autoCropArea="1"
                fillColor="#000"
                alt="Uploaded Image"
                ref="cropper"
                class="cropper"
              />
              <div class="controls">
                <button @click.prevent="saveCropper(slide)" class="btn btn-primary">Save Crop</button>
              </div>
            </div>
          </aside>
        </transition>
      </section>
      <input class="btn btn-primary" type="submit" value="Save Slider" />
    </form>

    <!-- Confirmation Modal -->
    <transition name="fade">
      <aside v-if="isConfirmationModalOpen" class="modal">
        <div class="container">
          <h2>Remove Slide</h2>
          <p>Are you sure you want to delete this slide? This action cannot be undone.</p>
          <div class="controls">
            <input @click="isConfirmationModalOpen = false" type="submit" value="Cancel" class="btn-primary" />
            <input @click="removeSlide" type="submit" value="Yes, Remove Slide" class="btn-outline-primary" />
          </div>
        </div>
      </aside>
    </transition>

    <div class="toast-container">
      <BaseToast v-if="toastMessage.isShown" :toast="toastMessage" />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumbs from '@/components/BaseBreadcrumbs.vue';
import BaseTabSelector from '@/components/BaseTabSelector.vue';
import BaseFileUpload from '@/components/BaseFileUpload.vue';
import BaseToast from '@/components/BaseToast.vue';
import ApiService from '@/services/api.service';
import PlusIcon from '@/assets/icons/plus.svg';
import CloseIcon from '@/assets/icons/exit.svg';
// import BaseModal from '@/components/BaseModal.vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'cms-edit-slider-form',
  components: { BaseBreadcrumbs, BaseFileUpload, BaseTabSelector, BaseToast, CloseIcon, PlusIcon, VueCropper },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.activeSlide) {
        ApiService.get('/admin/slider').then(({ data }) => {
          vm.slides = data?.data?.slider?.map((slide, index) => {
            vm.errors.push({ assetFile: null, name: null });
            slide.label = 'Slide';
            slide.value = index;
            slide.error = false;
            slide.assetFile = '';
            return slide;
          });
          vm.activeSlide = vm.slides[0];
        });
      }
    });
  },
  data() {
    return {
      activeSlide: null,
      slides: [],
      assetUploads: [],
      errors: [],
      toastMessage: {
        status: 'danger',
        message: '',
        isShown: false,
      },
      isConfirmationModalOpen: false,
      isCropperOpen: false,
      loader: null,
    };
  },
  computed: {
    objectUrl() {
      if (this.activeSlide.croppedDataURL) {
        return this.activeSlide.croppedDataURL;
      }
      return this.activeSlide.assetFile ? URL.createObjectURL(this.activeSlide.assetFile) : null;
    },
    currentErrors() {
      return this.errors[this.slides.findIndex((slide) => slide.value === this.activeSlide.value)] || {};
    },
    activeCropper() {
      return this.$refs.cropper[this.slides.findIndex((slide) => slide.value === this.activeSlide.value)];
    },
  },
  methods: {
    addSlide() {
      if (this.slides.length < 5) {
        this.slides.push({
          label: 'Slide',
          value: Date.now(),
          text: '',
          backgroundImage: '',
          presignedBackgroundImage: '',
          croppedDataURL: null,
          assetFile: null,
          error: false,
        });
        this.errors.push({ assetFile: null, name: null });
        this.activeSlide = this.slides[this.slides.length - 1];
      }
    },
    removeSlide() {
      const index = this.slides.findIndex((slide) => slide.value === this.activeSlide.value);
      this.slides.splice(index, 1);
      this.errors.splice(index, 1);
      this.activeSlide = this.slides[index] || this.slides[index + 1] || this.slides[index - 1];
      this.isConfirmationModalOpen = false;
    },
    fileAdded({ event, slide }) {
      if (event) {
        slide.assetFile = event;
        this.isCropperOpen = true;
      }
    },
    saveCropper(slide) {
      this.activeSlide = {
        ...this.activeSlide,
        croppedDataURL: this.activeCropper.getCroppedCanvas().toDataURL(slide.assetFile.type),
      };
      slide.croppedDataURL = this.activeCropper.getCroppedCanvas().toDataURL(slide.assetFile.type);
      this.isCropperOpen = false;
    },
    validateForm() {
      this.slides.forEach((slide, index) => {
        if (!slide.backgroundImage && !slide.assetFile) {
          this.errors[index].assetFile = {};
          this.errors[index].assetFile.messages = [];
          this.errors[index].assetFile.messages.push('Please upload an image for the slider.');
          this.activeSlide = slide;
          this.slides[index].error = true;
        } else {
          this.errors[index].assetFile = null;
          this.errors[index].name = null;
          slide.error = false;
          const extension = this.getFileExtension(slide?.assetFile?.name || slide.backgroundImage);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'gif') {
            this.errors.assetFile = {};
            this.errors.assetFile.messages = [];
            this.errors.assetFile.messages.push('This is not a valid file type.');
          }
        }
      });
      return !this.errors.some((error) => error?.assetFile?.messages?.length);
    },
    setErrorMessage(error) {
      console.error(error);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.toastMessage.status = 'danger';
      this.toastMessage.message = 'There was an error submitting the form.';
      this.toastMessage.isShown = true;
      this.loader.hide();
    },
    submitForm() {
      if (this.validateForm()) {
        this.loader = this.$loading.show({ lockScroll: true });
        const presignedRequests = [];
        this.slides.forEach((slide) => {
          presignedRequests.push(this.getImagePostRequest(slide));
        });
        Promise.allSettled(presignedRequests).then((responses) => {
          const formattedSlides = [];
          const putRequests = [];
          responses.forEach((response) => {
            // Create slide object for final PUT
            const slideData = response?.value;
            formattedSlides.push({
              id: slideData.slide?.id || null,
              text: slideData.slide?.text,
              backgroundImage: slideData.data?.params?.Key || slideData.slide?.backgroundImage,
            });
            // Create array of put requests for new images
            if (response.value.data && response.value.slide) {
              putRequests.push(this.putImageRequest(response.value.data, response.value.slide));
            }
          });
          // If we have put requests put all of those then update, else skip S3 PUT and update
          if (putRequests.length) {
            Promise.allSettled(putRequests).then(() => {
              this.updateAdminSlider(formattedSlides);
            });
          } else {
            this.updateAdminSlider(formattedSlides);
          }
        });
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'danger';
        this.toastMessage.message = 'Please complete filling out the form.';
        this.toastMessage.isShown = true;
      }
    },
    getImagePostRequest(slide) {
      // Check to see if we've attached a new assetFile
      if (slide.assetFile) {
        const fileName = slide.assetFile.name;
        return ApiService.post(`/assets/upload?fileName=${fileName}`)
          .then((resp) => ({ data: resp?.data?.data, slide }))
          .catch((error) => {
            this.setErrorMessage(error);
            Promise.reject(Error(error));
          });
      }
      // Resolve with current slide if we don't have a assetFile
      return Promise.resolve({ slide });
    },
    putImageRequest(uploadResponse, slide) {
      const s3Url = uploadResponse?.url;
      slide.s3Key = uploadResponse?.params?.Key;
      return ApiService.putS3(s3Url, slide.croppedDataURL)
        .then(() => Promise.resolve(slide))
        .catch((error) => {
          this.setErrorMessage(error);
        });
    },
    updateAdminSlider(formattedSlides) {
      return ApiService.put('/admin/slider', formattedSlides).then(({ data }) => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'success';
        this.toastMessage.message = 'The slider has been updated successfully.';
        this.toastMessage.isShown = true;

        this.slides = data?.data?.slider?.map((slide, index) => {
          slide.label = 'Slide';
          slide.assetFile = '';
          slide.value = index;
          slide.error = false;
          return slide;
        });
        const index = this.slides.findIndex((slide) => slide.value === this.activeSlide.value);
        if (index !== -1) {
          this.activeSlide = this.slides[index] || this.slides[index + 1] || this.slides[index - 1];
        }

        this.loader.hide();
      });
    },
    getFileExtension(filename) {
      const lastDot = filename.lastIndexOf('.');
      return filename.substring(lastDot + 1)?.toLowerCase();
    },
    fileUploadErrorHandler(error) {
      if (!this.currentErrors.assetFile) {
        this.currentErrors.assetFile = {};
        this.currentErrors.assetFile.messages = [];
        this.currentErrors.assetFile.messages.push(error);
      } else {
        this.currentErrors.assetFile.messages.push(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-edit-form {
  display: flex;
  flex-direction: column;
  padding: 40px;
  .controls {
    display: flex;
    .button {
      display: flex;
      align-items: center;
      min-width: 135px;
      font-family: $wide;
      font-size: 14px;
      cursor: pointer;
      &.close {
        color: $error;
      }
      &:not(:first-child) {
        margin-left: 30px;
      }
      .plus,
      .x {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
      .x path {
        stroke: $error;
      }
    }
  }
  .slider-form {
    margin-top: 40px;
    > section {
      .form-group {
        max-width: 600px;
      }
      .preview-container {
        position: relative;
        max-width: 950px;
        margin-bottom: 40px;
        .text {
          position: absolute;
          padding: 2rem;
          font-family: $extended;
          font-size: 2rem;
          font-weight: bold;
          color: white;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        .placeholder {
          width: 100%;
          max-width: 950px;
          height: 278px;
          background: $grey--soft;
        }
      }
    }
    .btn-primary {
      font-family: $wide;
      font-size: 16px;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000000b3;
  outline: 0;
  .container {
    padding: 40px;
    background: $white;
    h2 {
      margin-bottom: 20px;
      font-family: $extended;
      font-size: 24px;
    }
    p {
      font-family: $wide;
      font-size: 16px;
    }
    .controls {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      input {
        width: 209px;
        height: 56px;
        font-family: $wide;
        font-size: 16px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
